import React from 'react'
import {log} from '@vanguard/logger'

import {useRequiredAuth} from '../stores/AuthStore'
import {Album} from '../types'
import {api} from './api'
import {ResolvedReturn} from './api/photos/processPhoto'

interface StatusReturnSuccess {
  status: 'success'
  message: string
}

interface StatusReturnFailure {
  status: 'failure'
  message: string
}

type StatusReturn = StatusReturnSuccess | StatusReturnFailure

interface Params {
  albumID: Album['ID']
}

interface Return {
  processPhoto(params: {
    fileName: string
    uploadSessionID: string
  }): Promise<StatusReturn>
}

type UseProcessAlbumPhotosService = (params: Params) => Return

// TODO: This can be made into a util to allow retrying of any 'SERVICE' Api as they shoudl all follow the same 'ResolvedResult' pattern
const retry = async <T extends ResolvedReturn>(
  fn: () => Promise<T>,
  retriesLeft = 3,
  interval = 1000,
  exponential = true,
): Promise<T> => {
  try {
    const val = await fn()

    if (val.result === 'failure') {
      throw new Error('Failure Response')
    }

    return val
  } catch (error) {
    log.breadcrumb('Retriable HTTP Request Errored', {retriesLeft, error})

    if (retriesLeft) {
      await new Promise((r) => {
        setTimeout(r, interval)
      }) // TODO: Maybe utils wait method?

      return retry(
        fn,
        retriesLeft - 1,
        exponential ? interval * 2 : interval,
        exponential,
      )
    }

    throw new Error('Max retries reached for retriable HTTP Request')
  }
}

export const useProcessAlbumPhotosService: UseProcessAlbumPhotosService = ({
  albumID,
}) => {
  const {client} = useRequiredAuth()

  const processPhoto: Return['processPhoto'] = React.useCallback(
    async ({fileName, uploadSessionID}) => {
      const params = {
        clientID: client.ID,
        seasonID: client.currentSeasonID,
        albumID,
        fileName,
        uploadSessionID,
      }

      let response: StatusReturn
      try {
        log.breadcrumb('BeginProcess Request', params) // TOOD: This could be noisy
        await retry(() => api.photos.processPhoto(params))

        response = {
          status: 'success',
          message: '',
        }
      } catch (e) {
        if (e instanceof Error) {
          log.error(e, (event) => {
            // eslint-disable-next-line no-param-reassign
            event.context = `${event.context}::ProcessPhoto`

            event.addMetadata('photo details', params)
          })
        }
        response = {
          status: 'failure',
          message: 'Unable to request processing of photo',
        }
      }
      return response
    },
    [client.ID, client.currentSeasonID, albumID],
  )

  const returnVal = React.useMemo(
    () => ({
      processPhoto,
    }),
    [processPhoto],
  )

  return returnVal
}
