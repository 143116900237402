import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {ResourceID} from '../../../types'
import {handleError} from '../handleError'
import {CreateAlbumUploadSessionResponse} from '../../coreApi/types'

interface Params {
  ID: string
  albumID: ResourceID
  clientID: string
  photoFileNames: string[]
}

type ResolvedReturn = APIResponse<CreateAlbumUploadSessionResponse>

export interface CreateAlbumUploadSession {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.createAlbumUploadSession()'
const GENERIC_ERROR =
  'Something went wrong while creating your albumUploadSession. Please try again.'

export const createAlbumUploadSession: CreateAlbumUploadSession = async ({
  ID,
  albumID,
  clientID,
  photoFileNames,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ID,
      AlbumID: parseInt(albumID, 10),
      ClientID: parseInt(clientID, 10),
      PhotoFileNames: photoFileNames,
    }

    const response = await coreAlbums.createAlbumUploadSession(
      http,
      rawParamMap,
    )

    if (!response || !response.data) {
      throw new Error('Unsuccessful AlbumUploadSession Create')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        ID: response.data.ID.toString(),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
