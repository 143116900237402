import React, {useCallback} from 'react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {SortableContext} from '@dnd-kit/sortable'
import {
  Alert,
  AlertDescription,
  Box,
  CloseButton,
  Collapse,
  Flex,
  Image,
  SimpleGrid,
} from '@chakra-ui/react'

import {useStatusToast} from '../../../app/ToastManager'
import {Album, PropsOf} from '../../../types'
import {useAlbumService} from '../../../services/useAlbumService'
import {arrayMove} from '../../../utils'

import {SortableItem} from './SortableItem'
import {
  useAlbumPhotosDispatch,
  useAlbumPhotosState,
} from '../AlbumPhotosManager'

import {useAlbumDispatch} from '../AlbumManager'
import copy from '../copy.json'
import {isLegacyAlbum} from '../../../utils/isLegacyAlbum'
import {CustomIcon} from '../../../components'

interface Props {
  isVisible: boolean
  album: Album
}

export const SortableGallery: React.FC<React.PropsWithChildren<Props>> = ({
  isVisible,
  album,
}) => {
  const {data: photos} = useAlbumPhotosState()

  const albumPhotosDispatch = useAlbumPhotosDispatch()
  const albumDispatch = useAlbumDispatch()

  const {sortPhotos} = useAlbumService()
  const {toast} = useStatusToast()

  const [activeID, setActiveID] = React.useState<string | null>(null)
  const [showLegacyBanner, setShowLegacyBanner] = React.useState(
    album && isLegacyAlbum(album),
  )

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        distance: 5,
      },
    }),
  )

  const handleOnDragStart: PropsOf<typeof DndContext>['onDragStart'] =
    useCallback((event: DragStartEvent) => {
      const {active} = event

      setActiveID(active.id.toString())
    }, [])

  const handleOnDragEnd: PropsOf<typeof DndContext>['onDragEnd'] = useCallback(
    async (event: DragEndEvent) => {
      const {active, over} = event

      if (over && active.id !== over.id) {
        const originalPhotos = [...photos]
        const oldIndex = photos.findIndex((photo) => photo.ID === active.id)
        const newIndex = photos.findIndex((photo) => photo.ID === over.id)

        const newPhotosArray = arrayMove(photos, oldIndex, newIndex)

        albumPhotosDispatch({type: 'SET_PHOTO_DATA', payload: newPhotosArray})

        const firstIndex = Math.min(oldIndex, newIndex)
        const changedPhotos = newPhotosArray.slice(
          firstIndex,
          Math.max(oldIndex, newIndex) + 1,
        )

        const params = {
          albumID: album.ID,
          nextPhotoID:
            firstIndex !== 0 ? newPhotosArray[firstIndex - 1].ID : null,
          photoIDs: changedPhotos.map((p) => p.ID),
        }

        const response = await sortPhotos(params)

        if (response === 'failure') {
          toast.error('Sort Failed')
          albumPhotosDispatch({type: 'SET_PHOTO_DATA', payload: originalPhotos})
        } else if (album.sortType !== 'Custom') {
          albumDispatch({
            type: 'RESET',
          })
        }
      }

      setActiveID(null)
    },
    [
      album.ID,
      album.sortType,
      albumDispatch,
      albumPhotosDispatch,
      photos,
      sortPhotos,
      toast,
    ],
  )

  if (!isVisible) return null

  return (
    <Flex direction='column'>
      <Collapse in={showLegacyBanner}>
        <Alert
          borderWidth='1px'
          borderStyle='solid'
          borderRadius='4px'
          borderColor='#1F2223'
          backgroundColor='#DFE8ED'
          p={['1em']}
          marginBottom={8}>
          {/* <AlertIcon as={bannerIcon} /> */}
          <CustomIcon
            boxSize={12}
            display={['none', 'initial']}
            marginRight={8}
            name='blue-info'
          />
          <AlertDescription>
            {copy.banner.legacy}
            {/*
            Commenting this code for the future. Turns out this link will be enabled in another project.
            <Link href='https://campminder.com' isExternal>
              <span style={{textDecorationLine: 'underline'}}>
                {copy.banner.legacyAlbumLink}
              </span>
              .
              <ExternalLinkIcon boxSize={5} marginLeft={1} />
            </Link> */}
          </AlertDescription>

          <CloseButton
            alignSelf={['flex-start', 'unset']}
            size='lg'
            data-testid='alertCloseButton'
            onClick={(): void => {
              setShowLegacyBanner(false)
            }}
            marginLeft={[8, 16]}
          />
        </Alert>
      </Collapse>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}>
        <SortableContext
          items={photos.map(
            (p, index) => p.ID?.toString() || `no-key-${index}`,
          )}>
          <SimpleGrid
            spacing={['20px', '40px']}
            minChildWidth='200px'
            data-testid='sortable-gallery'>
            {photos.map((p, i) => {
              return (
                <SortableItem
                  data-testid='sortable-item'
                  id={p.ID || i.toString()}
                  key={p.ID}>
                  {p.ID !== activeID ? (
                    <>
                      <Image
                        key={p.ID}
                        src={`${p.thumbnailURL}&subscription-key=${process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY}`}
                        boxSize={['100%', '200px']}
                        bg='#F0F0F0'
                        maxHeight='200px'
                        objectFit='contain'
                      />
                      <Box
                        lineHeight='20px'
                        overflowWrap='break-word'
                        width={['100%', '200px']}>
                        {p.originalFileName}
                      </Box>
                    </>
                  ) : (
                    <Box key={p.ID} boxSize='200px' bg='#FFF' />
                  )}
                </SortableItem>
              )
            })}
          </SimpleGrid>
        </SortableContext>
        <DragOverlay>
          {activeID ? (
            <Image
              src={`${
                photos.find((p) => p.ID === activeID)?.thumbnailURL
              }&subscription-key=${
                process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY
              }`}
              boxSize='200px'
              bg='#F0F0F0'
              objectFit='contain'
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </Flex>
  )
}
