import React from 'react'
import {
  Box,
  Flex,
  MenuItem,
  MenuDivider,
  Spinner,
  useBreakpointValue,
} from '@chakra-ui/react'
import {GalleryImage} from './GalleryImage'
import {FilenameOverlay} from './FilenameOverlay'
import {PhotoGalleryActions} from './types'
import {SnowmanMenu} from './SnowmanMenu'

interface GalleryImageCardProps {
  href?: string
  fallbackPhoto: string
  actions: PhotoGalleryActions[]
  testID?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  photo: any
  margin: number
  index: number
}

export const GalleryImageCard: React.FC<GalleryImageCardProps> = ({
  fallbackPhoto,
  actions,
  photo,
  margin,
  index,
  testID,
  href,
}) => {
  const lightboxLinkRef = React.useRef<HTMLImageElement>(null)
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false)
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})

  const GalleryImageComponent = (
    <GalleryImage
      src={`${photo.src}&cachebust=${photo.rotation}${photo.processing}${photo.height}${photo.width}&subscription-key=${process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY}`}
      srcHeight={photo.height}
      srcWidth={photo.width}
      margin={margin}
      index={index}
      disableCheckboxes
      fallbackPhoto={fallbackPhoto}
      rotation={photo.rotation}
      processing={photo.processing}
      clickRef={lightboxLinkRef}
    />
  )

  return (
    <Flex
      position='relative'
      flexDir='row'
      alignItems='flex-start'
      data-testid={testID}
      onMouseEnter={(): void => setIsOverlayVisible(true)}
      onMouseLeave={(): void => setIsOverlayVisible(false)}
      key={photo.key}>
      {photo.processing ? (
        GalleryImageComponent
      ) : (
        <a
          href={href}
          data-attribute='SRL'
          onClick={(event) => {
            event.preventDefault()
          }}>
          {GalleryImageComponent}

          <FilenameOverlay
            visible={isOverlayVisible || !!isMobile}
            filename={photo.originalFileName}
            margin={margin}
            clickRef={lightboxLinkRef}
          />
        </a>
      )}

      <Box
        justifyContent='center'
        alignItems='flex-start'
        position='absolute'
        right='0'
        top='0'
        zIndex='5'
        mr={2}
        mt={3}>
        <SnowmanMenu placement='auto'>
          {actions
            .filter(
              (action) =>
                // disable delete and make album cover if processing
                !(
                  photo.processing &&
                  (action.name === 'Delete' ||
                    action.name === 'Make Album Cover')
                ),
            )
            .map((action, actionIndex, actionsArr) => (
              <React.Fragment key={`photo-${action.name}`}>
                <MenuItem onClick={action.onClick(photo.key)}>
                  {action.name}
                </MenuItem>
                {actionIndex < actionsArr.length - 1 ? (
                  <MenuDivider borderBottom='.1rem solid' />
                ) : null}
              </React.Fragment>
            ))}
        </SnowmanMenu>
      </Box>

      {photo.processing && (
        <Flex
          position='absolute'
          margin='10px'
          data-testid='processing-spinner'>
          <Spinner color='white' size='md' thickness='5px' />
        </Flex>
      )}
    </Flex>
  )
}
