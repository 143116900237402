/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {FC, useState} from 'react'

import {Flex, Checkbox} from '@chakra-ui/react'

export interface ClickArgs {
  index: number
}

export interface GalleryImageProps {
  src: string
  srcWidth: number
  srcHeight: number
  margin?: number
  index: number
  disableCheckboxes?: boolean
  fallbackPhoto: string
  rotation?: 0 | 90 | 180 | 270
  processing?: boolean
  onImageClick?: (
    arg0: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    arg1: ClickArgs,
  ) => void
  onImageSelected?: (arg0: string) => void
  opacity?: number
  clickRef?: React.RefObject<HTMLImageElement>
}

const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
}

export const GalleryImage: FC<React.PropsWithChildren<GalleryImageProps>> = (
  props,
) => {
  const {
    src,
    srcWidth,
    srcHeight,
    margin,
    index,
    children,
    disableCheckboxes,
    fallbackPhoto,
    rotation,
    processing,
    onImageClick,
    onImageSelected,
    opacity = 1,
    clickRef,
  } = props
  const [isChecked, setIsChecked] = useState(false)

  const handleOnCheckboxClick = (): void => {
    setIsChecked(!isChecked)

    if (onImageSelected) {
      onImageSelected(src.toString())
    }
  }

  const handleImageClick = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ): void => {
    if (onImageClick) {
      onImageClick(event, {index})
    }
  }

  const imgStyle = {
    margin,
    opacity,
    display: 'block',
    transition:
      'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
  }

  const onImageError = (
    event: React.SyntheticEvent<HTMLCanvasElement, Event>,
  ): void => {
    // eslint-disable-next-line no-unused-expressions
    event.currentTarget?.setAttribute('src', fallbackPhoto)
  }

  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  React.useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      if (context) {
        // If we don't clear on each resize rerender you end up
        // drawing multiple images on the canvas
        context.clearRect(0, 0, srcWidth, srcHeight)

        const image = new Image()
        image.src = src

        image.onload = (): void => {
          // save() and restore() are needed before and after everything else and
          // I'm not sure exactly why
          context.save()

          // move the origin to the center of the canvas. The image's top left corner
          // will render in the center of the canvas and the move it back
          // to the top right corner of the canvas with the 2nd and 3rd param offsets
          // in the drawImage() calls below
          context.translate(srcWidth / 2, srcHeight / 2)

          if (rotation) {
            // if (rotation && processing) {
            context.rotate((rotation * Math.PI) / 180) // angle in radians
          }

          // Height and width are switched base on whether we switch orientation
          if (!rotation || rotation === 180) {
            // if (!rotation || rotation === 180 || !processing) {
            context.drawImage(
              image,
              -srcWidth / 2,
              -srcHeight / 2,
              srcWidth,
              srcHeight,
            )
          } else {
            context.drawImage(
              image,
              -srcHeight / 2,
              -srcWidth / 2,
              srcHeight,
              srcWidth,
            )
          }

          // save() and restore() are needed before and after everything else and
          // I'm not sure exactly why
          context.restore()
        }
      }
    }
  }, [src, srcHeight, srcWidth, rotation])
  // }, [src, srcHeight, srcWidth, rotation, processing])

  return (
    <Flex position='relative' flexDir='row' alignItems='flex-start'>
      <img
        src={src}
        alt=''
        ref={clickRef}
        style={{
          ...imgStyle,
          position: 'absolute',
          height: '100%',
          width: '100%',
          opacity: 0,
        }}
      />
      {processing && (
        <Flex
          alignItems='flex-end'
          padding='5px'
          style={{
            color: 'white',
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: 'black',
            opacity: 0.6,
          }}
        />
      )}
      <canvas
        ref={canvasRef}
        width={srcWidth}
        height={srcHeight}
        style={
          isChecked && !disableCheckboxes
            ? {...imgStyle, ...selectedImgStyle}
            : {...imgStyle}
        }
        onClick={onImageClick ? handleImageClick : undefined}
        onError={onImageError}
      />
      <Checkbox
        position='absolute'
        left='2%'
        top='2%'
        onChange={handleOnCheckboxClick}
        display={disableCheckboxes ? 'none' : ''}
      />
      {children}
    </Flex>
  )
}
