import {AxiosResponse} from 'axios'
import {RawPerson} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  clientID: string
  personID: string
}
export interface GetPerson<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const getPerson: GetPerson<Params, RawPerson> = (
  http,
  {personID, clientID},
) => {
  try {
    return http.get(`/v1/persons/${personID}?clientid=${clientID}`)
  } catch (e) {
    // todo: should we bugsnag here?, its probably redundant and will also be handled in 'service'?
    if (e instanceof Error) {
      throw e
    } else {
      throw new Error(e as string)
    }
  }
}
