import React from 'react'
import {Flex, Box, Button, Text} from '@chakra-ui/react'
import moment from 'moment'

import copy from './copy.json'
import {AlbumNameControl} from './AlbumNameControl'
import {AlbumDateControl} from './AlbumDateControl'
import {AlbumTimeControl} from './AlbumTimeControl'
import {isLegacyAlbumDate} from '../../utils/isLegacyAlbum'
import {
  useComposeAlbumState,
  useComposeAlbumActions,
} from './contextManagers/ComposeAlbumManager'
import {AlbumSortType, PropsOf} from '../../types'
import {ORDERED_PHOTO_SORT_TYPES} from '../../views/AlbumView/types'
import {INSERT_PLACEHOLDER_ID} from '../../constants'
import {browserSupportsInputType} from '../../utils'
import {ActionButton} from '../ActionButton/ActionButton'
import {StatusReturn} from '../../services/useAlbumService'
import {useModalContext} from './subcomps/ComposeAlbumModalContext'
import {Visible} from '../Visible/Visible'
import {FormSelect} from '../FormSelect/FormSelect'
import {CustomIcon} from '../CustomIcon/CustomIcon'
import {appTheme} from '../../app/theme'

const MAX_ALBUM_NAME_LENGTH = 100

const Header: React.FC<React.PropsWithChildren> = () => (
  <Box textAlign='center' flex={1}>
    Album Details
  </Box>
)

const Body: React.FC<React.PropsWithChildren> = () => {
  const {name, date, sortType, constraints} = useComposeAlbumState()

  const initialFocusRef = React.useRef(null)
  const {setValue} = useComposeAlbumActions()
  const {setCurrentStep} = useModalContext()

  const handleTimeControlChange = (militaryTime: string): void => {
    setValue(
      'date',
      new Date(`${moment(date).format('LL')} ${militaryTime || null}`),
    )
  }

  const handleDateControlChange: PropsOf<
    typeof AlbumDateControl
  >['onChange'] = (d): void => {
    if (d !== null) {
      setValue(
        'date',
        new Date(
          `${moment(d).format('LL')} ${moment(d).format('HH:mm') || null}`,
        ),
      )
    }
  }

  const handleContentTextChange: PropsOf<
    typeof AlbumNameControl
  >['onChange'] = (e) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[\r\n]/g, '') // prevent new lines

    if (e.currentTarget.value.length <= MAX_ALBUM_NAME_LENGTH) {
      setValue('name', e.currentTarget.value)
    }
  }

  const dateControlWidth = browserSupportsInputType('time')
    ? ['100%', '75%']
    : ['100%', '50%']
  const timeControlWidth = browserSupportsInputType('time')
    ? ['100%', '25%']
    : ['100%', '50%']

  const handleSortChange: PropsOf<typeof FormSelect>['onChange'] = (event) => {
    setValue('sortType', event.currentTarget.value)
  }
  return (
    <>
      <Flex py={2}>
        <Flex direction='column'>
          <Box p={1}>
            <Button
              data-testid='segment-button'
              aria-label='segment'
              leftIcon={<CustomIcon name='audience' />} // Audience Icon
              variant='outline'
              rounded='full'
              size='sm'
              color={appTheme.colors.lightText}
              fontWeight='normal'
              onClick={(): void => setCurrentStep('segment')}>
              {!constraints.length
                ? copy.steps.compose.constraintsPill.allAudiences
                : copy.steps.compose.constraintsPill.customAudience}
            </Button>
          </Box>
        </Flex>
      </Flex>

      <AlbumNameControl
        value={name}
        onChange={handleContentTextChange}
        ref={initialFocusRef}
      />

      <Flex direction={['column', 'row']}>
        <AlbumDateControl
          value={moment(date)}
          onChange={handleDateControlChange}
          width={dateControlWidth}
        />

        <AlbumTimeControl
          value={moment(date).format('HH:mm')}
          onChange={handleTimeControlChange}
          width={timeControlWidth}
          ml={[0, 4]}
        />
      </Flex>
      <Flex direction='column'>
        <Visible when={!isLegacyAlbumDate(date)}>
          <FormSelect
            data-testid='sort-order-select'
            title='sort'
            id='sort'
            name='sort'
            label='Album Sort'
            fontSize='16px'
            labelFontSize='14px'
            labelFontWeight='bold'
            labelPaddingTop={4}
            labelPaddingBottom={1}
            labelMarginBottom={0}
            value={sortType}
            onChange={handleSortChange}
            borderColor='#ced2d5'>
            {ORDERED_PHOTO_SORT_TYPES.filter(
              (t) => t.name !== 'Custom' || sortType === 'Custom',
            ).map((t) => (
              <option key={t.name} value={t.name}>
                {t.name}
              </option>
            ))}
          </FormSelect>
          <Flex height={9}>
            <Visible when={sortType === 'Custom'}>
              <Text fontSize='14px' color='#E05535'>
                {copy.warning.changeCustomSort}
              </Text>
            </Visible>
          </Flex>
        </Visible>
      </Flex>
    </>
  )
}

export interface FooterProps {
  handleReponse: (response: StatusReturn, sortType: AlbumSortType) => void
}

const Footer: React.FC<React.PropsWithChildren> = () => {
  const album = useComposeAlbumState()

  const {setCurrentStep} = useModalContext()

  const isEditMode = album.ID !== INSERT_PLACEHOLDER_ID
  const isValid = !!album.name && album.name.length > 0

  const onNextHandler = (): void => {
    if (
      isEditMode &&
      !album.isDraft &&
      (!album.publishDate || moment(album.publishDate).isBefore(new Date()))
    ) {
      setCurrentStep('published')
    } else {
      setCurrentStep('schedule')
    }
  }

  return (
    <Flex
      flexDir='row'
      justifyContent={['space-around', 'flex-end']}
      width='100%'>
      <ActionButton
        onClick={onNextHandler}
        isDisabled={!isValid}
        flexGrow={[1, 0]}>
        {copy.buttons.primaryAction.next}
      </ActionButton>
    </Flex>
  )
}

export const ComposeStepUI = {
  Header,
  Body,
  Footer,
}
