import axios, {
  RawAxiosRequestConfig,
  AxiosInstance,
  RawAxiosRequestHeaders,
} from 'axios'

export interface RequestConfig extends RawAxiosRequestConfig {
  baseURL: string
}

export type HttpInstance = AxiosInstance

interface CreateHttpInstance {
  (params: RequestConfig): HttpInstance
}

export const createHttpInstance: CreateHttpInstance = ({
  baseURL,
  ...params
}) => {
  let httpHeaders: RawAxiosRequestHeaders = {
    Accept: 'application/json',
    'Ocp-Apim-Subscription-Key':
      process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY || '',
    'Ocp-Apim-Trace': 'true',
  }

  if (process.env.REACT_APP_TARGET_DEFAULT_NAMESPACE) {
    httpHeaders = {
      ...httpHeaders,
      'Target-Default-Namespace':
        process.env.REACT_APP_TARGET_DEFAULT_NAMESPACE,
    }
  }

  return axios.create({
    baseURL,
    timeout: 15000,
    headers: httpHeaders,
    withCredentials: true,
    ...params,
  })
}
